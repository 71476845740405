.c-page {
    max-width: 100%;
    flex-grow: 1;
    padding: 0.5rem;
}

@media (min-width: 768px) {
    .c-page {
        padding: 1rem;
    }
}